@import "variables.scss";
.locationBoth {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 40em) {
    flex-direction: column;
  }
}
.tabContenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  flex: 1;
  img {
    opacity: 0.4;
    height: 500px;
    object-fit: cover;
    width: 100%;
  }
  .contactDetails {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 6.25em;
    .contactDetailsContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .contactDesc {
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      line-height: 1.5em;
      a {
        text-decoration: none;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }
    @media screen and (max-width: 40em) {
      top: 3.125em;
    }
  }
}
