@import "variables.scss";
.cascading {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  gap: 5px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &__image {
    object-fit: cover;
  }
}
.imageContainer {
  &:hover {
    scale: 105%;
    cursor: pointer;
  }
  position: relative;
  width: 100px;
  height: 500px;
  background-color: $accent;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
}
.imageText {
  position: absolute;
  bottom: 10px;
  left: 10px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  color: rgb(254, 254, 254);
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    writing-mode: initial;
    text-orientation: initial;
    transform: none;
  }
}
.hexagonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 55em;
  margin: auto;
  a {
    text-decoration: none;
  }
}

.hexagon {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: $accent;
  margin: 29px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  font-size: 1.5em;
  color: white;
  margin: 5px;
  @media screen and (max-width: 40em) {
    width: 150px;
    height: 150px;
    font-size: 1.25em;
  }
}
