@import "variables.scss";
.mainFooter {
  background-color: $bckgColor;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  .logo {
    max-width: 150px;
    width: 100%;
    flex: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    .slogan {
      font-size: 1em;
      line-height: 1.5em;
      font-weight: bold;
    }
    .socialNetwork {
      display: flex;
      justify-content: space-around;
      align-items: center;
      > a {
        font-size: 1.5em;
        padding: 5px;
        text-decoration: none;
        color: white;
      }
    }
  }
  .tradeMark {
    flex: 1;
  }
  @media screen and (max-width: 40em) {
    flex-direction: column;
    > div {
      padding: 10px;
    }
  }
}
