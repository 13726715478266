@import "variables.scss";
.contact {
  padding: 100px 75px;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  color: $white;
  background-color: $bckgColor;
  h2 {
    font-size: 2.5em;
    line-height: 2.5em;
    margin: 0;
    color: $accent;
  }
  a {
    display: block;
    font-size: 1.75em;
    line-height: 1;
    text-decoration: none;
    color: $white;
    font-weight: bold;
    text-decoration: underline;
  }
  @media screen and (max-width: 60em) {
    padding: 50px 35px;
    h2 {
      font-size: 1.5em;
      line-height: 1.25em;
    }
    a {
      font-size: 1.25em;
      line-height: 1.25em;
    }
  }
}
