@import "variables.scss";
.mainHome {
  max-height: calc(100vh);
  position: relative;
  img {
    width: 100%;
    height: calc(100vh);
    object-fit: cover;
    opacity: 0.7;
  }
  .mainDiv {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 5.5em;
      line-height: 1;
      color: $accent;
      margin: 0;
    }
    h2 {
      font-size: 3.5em;
      line-height: 1;
      color: $white;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: baseline;
      .dot {
        height: 15px;
        width: 15px;
        background-color: $accent;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        @media screen and (max-width: 40em) {
          height: 15px;
          width: 15px;
        }
      }
    }
    @media screen and (max-width: 40em) {
      h1 {
        font-size: 3.5em;
        line-height: 1.5em;
      }
      h2 {
        font-size: 1.5em;
        line-height: 1;
      }
    }
  }
}
