@import "variables.scss";
.mainTestimonials {
  padding: 0 75px 100px;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  color: $white;
  background-color: $bckgColor;
  .mainWidthTestimonials {
    max-width: 80%;
    width: 100%;
    margin: auto;
    @media screen and (max-width: 40em) {
      max-width: 90%;
    }
  }
  @media screen and (max-width: 40em) {
    padding: 0 50px 0;
    overflow-x: hidden;
  }
}
