@import "variables.scss";
.services {
  padding: 100px 75px;
  text-align: center;
  color: $white;
  background-color: $bckgColor;
  .servicesCont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3em 0;
    .servicesImages {
      flex: 1;
      width: calc(50% - 20px);
      margin: 20px;
      img {
        height: 500px;
        object-fit: contain;
        width: 100%;
        @media screen and (max-width: 60em) {
          height: 300px;
        }
      }
    }
    .servicesText {
      flex: 1;
      width: calc(50% - 20px);
      margin: 20px;
    }
    @media screen and (max-width: 60em) {
      flex-direction: column;
      .servicesImages {
        padding: 20px;
        width: 100%;
        margin: 0;
      }
      .servicesText {
        padding: 20px;
        width: 95%;
      }
    }
    h3 {
      font-size: 1.5em;
      line-height: 2.5em;
      color: $accent;
      margin: 0;
      text-align: left;
      @media screen and (max-width: 60em) {
        text-align: center;
      }
    }
    p {
      color: $white;
      font-size: 1.15em;
      line-height: 1.15em;
      margin: 0;
      text-align: left;
      @media screen and (max-width: 60em) {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 40em) {
    padding: 50px 0;
    overflow-x: hidden;
  }
}

.changeOrder {
  > div:first-child {
    order: 2;
  }
  > div:last-child {
    order: 1;
  }
  @media screen and (max-width: 60em) {
    > div:first-child {
      order: 1;
    }
    > div:last-child {
      order: 2;
    }
  }
}

.shimmerContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shimmerEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image:not(.loading) {
  opacity: 1;
}
