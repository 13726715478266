@import "variables.scss";

.mainHeader {
  z-index: 100;
  position: relative;
}

.hide {
  visibility: hidden;
}

.menu {
  background-color: transparent;
  color: $white;
  & li a {
    color: $white;
  }
  & li a:hover {
    background-color: $accent;
  }

  @media screen and (max-width: 48em) {
    background-color: $bckgColor;
  }
}
.header {
  max-width: 100%;
  padding: 15px 20px;
  margin: 0px auto;
  color: $white;
  position: fixed;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  &.bckg {
    background-color: black;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  li a {
    display: block;
    padding: 15px 20px;
    color: $white;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      background-color: $accent;
    }
  }
  .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }

  /* menu icon */

  .menuIcon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
    & .navIcon {
      background: transparent;
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;
    }

    & .navIcon:before,
    & .navIcon:after {
      background: $white;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
    }

    & .navIcon:before {
      top: 5px;
    }

    & .navIcon:after {
      top: -5px;
    }
  }

  /* menu btn */

  .menuBtn {
    display: none;

    &:checked ~ .menu {
      max-height: 486px;
    }

    &:checked ~ .menuIcon .navIcon {
      background: $grey;
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;
    }

    &:checked ~ .menuIcon .navIcon:before {
      transform: rotate(-45deg);
    }

    &:checked ~ .menuIcon .navIcon:after {
      transform: rotate(45deg);
    }

    &:checked ~ .menuIcon:not(.steps) .navIcon:before,
    &:checked ~ .menuIcon:not(.steps) .navIcon:after {
      top: 0;
    }
  }
  & .logo {
    display: block;
    flex: none;
    float: left;
    font-size: 1.5em;
    text-decoration: none;
    line-height: normal;
    margin-top: 5px;
    img {
      height: 2.5em;
      object-fit: contain;
      @media screen and (max-width: 40em) {
        height: 1.5em;
      }
    }
  }
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menuIcon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}
.greyColor {
  color: #868e96;
}
