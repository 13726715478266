@import "variables.scss";
.about {
  padding: 100px 75px;
  text-align: center;
  color: $white;
  background-color: $bckgColor;
  h2 {
    font-size: 2.5em;
    line-height: 2.5em;
    color: $accent;
    margin: 0;
  }
  p {
    color: $white;
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }
  @media screen and (max-width: 40em) {
    padding: 50px 25px;
    h2 {
      font-size: 1.5em;
      line-height: 2.5em;
    }
    p {
      font-size: 1em;
      line-height: 1.5em;
    }
  }
}
